import React from "react";
import theme from "theme";
import { Theme, Link, Section, Text, Image, Box, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu, SocialMedia } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"exhibitions"} />
		<Helmet>
			<title>
				Özge Keskin
			</title>
			<meta name={"description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileColor"} content={"#5d1111"} />
		</Helmet>
		<Section background="#000000">
			<Menu
				display="flex"
				justify-content="center"
				font="--base"
				font-weight="700"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="#ffffff" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px" color="#ffffff" />
			</Menu>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" sm-align-self="center" />
			<Text
				font="--headline2"
				color="--dark"
				text-align="center"
				md-font="normal 700 35px/1.2 --fontFamily-sans"
				margin="16px 0px 0px 0px"
				lg-font="normal 900 42px/1.2 --fontFamily-sans"
				lg-color="#000000"
			>
				Exhibitions
			</Text>
			<Box
				display="flex"
				margin="40px 0 20px 0"
				justify-content="space-around"
				sm-flex-direction="column"
				sm-align-self="center"
			>
				<Box padding="10px" width="600px">
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/2-gr.jpeg?v=2023-01-11T22:19:34.549Z" width="320px" max-width="100%" />
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/5-gr.jpeg?v=2023-01-11T22:21:03.850Z" width="320px" max-width="100%" />
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/1-gr.jpeg?v=2023-01-11T22:07:32.618Z" width="320px" max-width="100%" />
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/7-gr.jpeg?v=2023-01-14T22:15:06.222Z" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px" width="600px">
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/6-gr.jpeg?v=2023-01-11T22:21:30.742Z" width="320px" max-width="100%" />
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/4-gr.jpeg?v=2023-01-11T22:20:26.349Z" width="320px" max-width="100%" />
					<Image src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/3-gr.jpeg?v=2023-01-11T22:19:56.925Z" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section background="#020000">
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
				text-align="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Section md-height="100px" text-align="center">
						<Override slot="SectionContent" text-align="center" />
						<Text
							font="--headline2"
							color="--light"
							text-align="center"
							md-font="normal 700 35px/1.2 --fontFamily-sans"
							margin="16px 0px 0px 0px"
							lg-font="normal 900 42px/1.2 --fontFamily-sans"
						>
							Shipping
						</Text>
					</Section>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						margin="0px 0px 16px 0px"
						justify-content="flex-start"
						md-margin="0px 0px 16px 0px"
					>
						<Box
							width="100%"
							align-items="flex-start"
							display="flex"
							justify-content="flex-start"
							md-width="100%"
							lg-width="100%"
						>
							<Image
								src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/shipping%20cl.jpeg?v=2023-01-13T08:41:12.519Z"
								border-radius="24px"
								max-width="100%"
								max-height="522px"
								width="100%"
								object-fit="cover"
								lg-max-height="392px"
								object-position="0% 30%"
								sm-max-height="213px"
							/>
						</Box>
					</LinkBox>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/kartt.jpg?v=2023-01-12T01:48:10.451Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							height="522px"
						/>
					</Box>
					<Text
						font="--lead"
						color="--light"
						text-align="center"
						md-width="100%"
						max-width="600px"
						md-font="normal 300 16px/1.5 --fontFamily-sans"
						sm-font="normal 300 12px/1.5 --fontFamily-sans"
						lg-font="normal 300 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					>
						For all my paintings that I exhibit IRL and release on WEB3/NFT space, I provide shipping of the original physical paintings for my collectors.
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Section background="--color-dark" padding="60px 0" sm-padding="40px 0">
			<SocialMedia twitter="https://twitter.com/ozgekeskin_" instagram="https://www.instagram.com/ozgekeskin.ok/" linked-in="https://www.linkedin.com/in/%C3%B6zge-keskin-854676b5">
				<Override
					slot="link"
					border-radius="50%"
					color="--grey"
					hover-color="--light"
					background="transparent"
					hover-background="transparent"
					margin="0 8px"
					border-color="#ffffff"
				/>
				<Override slot="icon" border-color="#f1f5f9" />
			</SocialMedia>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"63a0914a4a50a9001ffae405"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});