export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"63a0914a4a50a9001ffae40c",
				"63a0914a4a50a9001ffae40f",
				"63a6271ff0019100228d25c7",
				"63a61b3a5f451700242428dd",
				"63a61df29faa0f00208ed04c"
			]
		},
		"63a0914a4a50a9001ffae40c": {
			"id": "63a0914a4a50a9001ffae40c",
			"name": "404",
			"pageUrl": "404"
		},
		"63a0914a4a50a9001ffae40f": {
			"id": "63a0914a4a50a9001ffae40f",
			"name": "Home",
			"pageUrl": "index"
		},
		"63a61b3a5f451700242428dd": {
			"id": "63a61b3a5f451700242428dd",
			"pageUrl": "exhibitions",
			"name": "Exhibitions / Shipping"
		},
		"63a61df29faa0f00208ed04c": {
			"id": "63a61df29faa0f00208ed04c",
			"pageUrl": "architecture",
			"name": "VR / IRL Architecture"
		},
		"63a6271ff0019100228d25c7": {
			"id": "63a6271ff0019100228d25c7",
			"pageUrl": "nft",
			"name": "NFT Collections"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"description": "Artist / Interior Architect & Designer",
			"title": "Özge Keskin",
			"og:description": "Artist / Interior Architect & Designer",
			"og:image": "https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z",
			"favicon_32px": "https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z",
			"favicon_apple_152px": "https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z",
			"background_win10": "#5d1111"
		}
	}
}