import React from "react";
import theme from "theme";
import { Theme, Link, Section, Text, Image, Box, LinkBox, Strong, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu, SocialMedia } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"nft"} />
		<Helmet>
			<title>
				Özge Keskin
			</title>
			<meta name={"description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileColor"} content={"#5d1111"} />
		</Helmet>
		<Section background="#000000">
			<Menu
				display="flex"
				justify-content="center"
				font="--base"
				font-weight="700"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="#ffffff" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px" color="#ffffff" />
			</Menu>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						"I'm just a prisoner of my beautiful goals..."{"\n\n"}
					</Text>
					<Box
						width="55%"
						align-items="flex-start"
						display="inline-block"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/DUSUK%20%282%29.jpg?v=2023-04-30T23:31:35.956Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							object-position="0% 30%"
						/>
					</Box>
					<Section background="#000000">
						<Override slot="SectionContent" text-align="center" />
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							Every atomic particle in my body recognizes this feeling as I try to carry myself up ...A thread made of cotton...
							<br />
							How can it be so strong...?{"\n"}
							<br />
							{"\n\n"}
							<br />
							2023
						</Text>
					</Section>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						LIFT THE VEIL
					</Text>
					<Box
						width="52%"
						align-items="flex-start"
						display="inline-block"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/SONN.jpg?v=2023-04-30T23:45:32.176Z"
							border-radius="24px"
							max-width="100%"
							width="115%"
							object-fit="cover"
							object-position="0% 30%"
						/>
					</Box>
					<Section background="#000000">
						<Override slot="SectionContent" text-align="center" />
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							The mind is beyond the body...
When we love something that identifies with our soul, we lift a veil.
Everything that reaches our soul through our minds merges, flows, and becomes a whole.
							<br />
							<br />
							{"\n\n"}"The world can only heal itself that way."{"\n"}
							<br />
							<br />
							2023
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						UNDULATION
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/und1%20kopyas%C4%B1%202.jpg?v=2023-01-13T11:12:29.488Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							object-position="0% 30%"
						/>
					</Box>
					<Section background="#000000">
						<Override slot="SectionContent" text-align="center" />
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							“When you touch me, i can taste the air. And its taste is like waves running down all over my skin,
							<br />
							from my fingertips to my hair..."
							<br />
							<br />
							11.2022
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						WATERFALL
						<br />
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						sm-justify-content="center"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/waterfall%20try2.jpeg?v=2023-01-13T11:33:20.861Z"
							max-width="100%"
							width="100%"
							object-fit="cover"
							object-position="0% 30%"
							sm-width="340px"
							sm-text-align="center"
						/>
					</Box>
					<Section background="#000000">
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							‘’Our skin has tactile receptors. A single touch can reach a mechanical button that goes to the brain.
							<br />
							And the brain can create an emotional flow with a mechanical switch. ‘’
So us...
							<br />
							<br />
							10.2022
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
			height="2000px"
			md-height="1460px"
			lg-height="1400px"
			sm-height="1300px"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						SENSATION
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
						sm-justify-content="center"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/ssq%201.jpeg?v=2023-01-13T13:41:08.705Z"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="0% 30%"
							sm-width="340px"
							sm-text-align="center"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/ssq%202.jpeg?v=2023-01-13T13:50:26.945Z"
						max-width="100%"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-width="340px"
						sm-text-align="center"
					/>
					<Image
						src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/ssq%203.jpeg?v=2023-01-13T13:51:00.675Z"
						max-width="100%"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-width="340px"
						sm-text-align="center"
					/>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
					/>
					<Section background="#000000">
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							''Our body is like a sponge, absorbing all emotions.''
							<br />
							<br />
							08.2022 - 09.2022
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
			height="1070px"
			md-height="900px"
			lg-height="1100px"
			sm-height="670px"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
				sm-font="12px sans-serif"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						AFFLUX
					</Text>
					<Section>
						<Override slot="SectionContent" flex-direction="row" />
						<Components.QuarklycommunityKitVimeo video="https://vimeo.com/showcase/9804125/video/747035743" sm-padding="0px 7px 0px 0px">
							<Override slot="Vimeo Content" max-width="330px" lg-max-width="220px" />
						</Components.QuarklycommunityKitVimeo>
						<Components.QuarklycommunityKitVimeo video="https://vimeo.com/showcase/9804125/video/746993475" sm-padding="0px 0px 0px 7px">
							<Override slot="Vimeo Content" max-width="330px" lg-max-width="220px" />
						</Components.QuarklycommunityKitVimeo>
					</Section>
					<Section background="#000000">
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="300 16px --fontFamily-sans"
							text-align="center"
							lg-background="#000000"
							sm-font="300 11px --fontFamily-sans"
						>
							The story is a device.
							<br />
							Painting is a device.
							<br />
							Music is a device.
							<br />
							The algorithm is a device.
							<br />
							Artist is a device.
							<br />
							We don't care about devices, we're looking for "apparatus".
							<br />
							<br />
							AFFLUX is an apparatus.

The works are made with oil paint,physical textures,mixed techniques on canvas. A special soundtrack was then composed for the story of each painting. Then, the visual and sound were re-generated with the algorithm as audio-reactive and turned into animation.
							<br />
							<br />
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Afflux collection is a collaboration with Hazelrah.
								</Strong>
								<br />
								<br />
								08.2022
							</Span>
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
			height="1200px"
			md-height="700px"
			sm-height="550px"
			lg-height="1000px"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						SURREAL FEELING
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/Surreal%20feeling%20collage1.jpeg?v=2023-01-13T01:25:51.395Z"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="0% 30%"
							sm-max-height="213px"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/Surreal%20feeling%20collage.jpeg?v=2023-01-13T01:13:38.014Z"
						max-width="100%"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
					<Section background="#000000">
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							In this collection, I describe our emotions and emotional states. I call them surreal feelings.
							<br />
							<br />
							09.2021 - 04.2022
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						CELEBRATION
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="center"
						md-width="100%"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/cbr%20try2.jpeg?v=2023-01-13T11:39:41.828Z"
							border-radius="24px"
							max-width="100%"
							width="540px"
							object-fit="cover"
							object-position="0% 30%"
							display="inline-block"
							text-align="center"
							lg-width="400px"
							md-width="370px"
							sm-width="250px"
						/>
					</Box>
					<Section background="#000000">
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							Basically they say we have 5 senses; Sight, smell, hearing, taste and touch... For me, the most basic sense is feeling...
							<br />
							The sense of sensing where the body is in space. It's like a strong and soft celebration.
							<br />
							<br />
							04.2022
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
			height="700px"
			md-height="600px"
			sm-height="440px"
			lg-height="680px"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="#ffffff"
						font="40px --fontFamily-sans"
						lg-text-align="center"
						lg-align-self="center"
						sm-font="25px --fontFamily-sans"
					>
						DEBOUCH
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/debouch%20collage.jpeg?v=2023-01-13T00:55:31.660Z"
							border-radius="24px"
							max-width="100%"
							max-height="522px"
							width="100%"
							object-fit="cover"
							object-position="0% 30%"
							sm-max-height="213px"
						/>
					</Box>
					<Section background="#000000">
						<Text
							margin="0px 0px 0px 0px"
							background="#040000"
							color="#ffffff"
							font="16px --fontFamily-sans"
							text-align="center"
							sm-font="11px --fontFamily-sans"
						>
							This collection of mine describes the revelations from narrow spaces. Here, in fact, it metaphorically refers to the feelings of the characters I paint physically during that process.{"\n"}
							<br />
							<br />
							04.2021 - 02.2022{"\n\n\n"}
						</Text>
					</Section>
				</LinkBox>
			</Box>
		</Section>
		<Section background="--color-dark" padding="60px 0" sm-padding="40px 0">
			<SocialMedia twitter="https://twitter.com/ozgekeskin_" instagram="https://www.instagram.com/ozgekeskin.ok/" linked-in="https://www.linkedin.com/in/%C3%B6zge-keskin-854676b5">
				<Override
					slot="link"
					border-radius="50%"
					color="--grey"
					hover-color="--light"
					background="transparent"
					hover-background="transparent"
					margin="0 8px"
					border-color="#ffffff"
				/>
				<Override slot="icon" border-color="#f1f5f9" />
			</SocialMedia>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"63a0914a4a50a9001ffae405"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});