import React from "react";
import theme from "theme";
import { Theme, Link, Section, Text, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu, SocialMedia } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"architecture"} />
		<Helmet>
			<title>
				Özge Keskin
			</title>
			<meta name={"description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileColor"} content={"#5d1111"} />
		</Helmet>
		<Section background="#000000">
			<Menu
				display="flex"
				justify-content="center"
				font="--base"
				font-weight="700"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="#ffffff" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px" color="#ffffff" />
			</Menu>
		</Section>
		<Section
			text-align="center"
			padding="80px 0"
			sm-padding="40px 0"
			md-background="#000000"
			background="#000000"
		>
			<Text
				as="h2"
				font="normal 900 30px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				md-font="--headline3"
				margin="20px 0 0 0"
				border-color="#ffffff"
				color="#ffffff"
			>
				Virtual Reality Design{"\n\n\n"}
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 0 0"
				md-color="#fffbfb"
				color="#ffffff"
			>
				Interior architect and light designer / Archeist VR event hall
				<br />
				<br />
				{"\n\n\n"}
			</Text>
			<Box padding="10px">
				<Image
					src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/vr2-cr.jpg?v=2023-01-13T09:07:59.007Z"
					width="650px"
					max-width="100%"
					md-width="600px"
					lg-width="650px"
				/>
				<Image
					src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/vr3-cr.jpg?v=2023-01-13T09:07:58.988Z"
					width="650px"
					max-width="100%"
					md-width="600px"
					display="inline-block"
					lg-width="650px"
				/>
				<Image
					src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/vr1-cr.jpg?v=2023-01-13T09:07:58.998Z"
					width="650px"
					max-width="100%"
					md-width="600px"
					lg-width="650px"
				/>
				<Image
					src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/vr5-cr.jpg?v=2023-01-13T09:07:59.005Z"
					width="650px"
					max-width="100%"
					md-width="600px"
					lg-width="650px"
				/>
			</Box>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column" />
		</Section>
		<Section background="#000000">
			<Text
				as="h2"
				font="normal 900 30px/4 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				md-font="--headline3"
				margin="20px 0 0 0"
				border-color="#ffffff"
				color="#ffffff"
				text-align="center"
			>
				Interior Architecture, IRL
			</Text>
			<Box
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/2.jpeg?v=2023-01-11T22:31:06.671Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					sm-min-height="100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/3.jpeg?v=2023-01-11T22:32:10.110Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					sm-min-height="100vw"
					flex="0 1 auto"
				/>
			</Box>
		</Section>
		<Section background="#000000">
			<Override slot="SectionContent" align-items="center" />
			<Image
				src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/4.jpeg?v=2023-01-13T14:11:54.260Z"
				object-fit="cover"
				width="800px"
				height="100%"
				border-radius="24px"
				transform="translateY(0px)"
				transition="transform 0.2s ease-in-out 0s"
				hover-transform="translateY(-10px)"
				lg-display="block"
				lg-width="600px"
				md-width="460px"
				sm-width="380px"
			/>
		</Section>
		<Section background="#000000">
			<Override slot="SectionContent" align-items="center" />
			<Image
				src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/5.jpeg?v=2023-01-11T22:42:21.963Z"
				object-fit="cover"
				width="800px"
				height="100%"
				border-radius="24px"
				transform="translateY(0px)"
				transition="transform 0.2s ease-in-out 0s"
				hover-transform="translateY(-10px)"
				lg-display="block"
				lg-width="600px"
				md-width="460px"
				sm-width="380px"
			/>
		</Section>
		<Section background="--color-dark" padding="60px 0" sm-padding="40px 0">
			<SocialMedia twitter="https://twitter.com/ozgekeskin_" instagram="https://www.instagram.com/ozgekeskin.ok/" linked-in="https://www.linkedin.com/in/%C3%B6zge-keskin-854676b5">
				<Override
					slot="link"
					border-radius="50%"
					color="--grey"
					hover-color="--light"
					background="transparent"
					hover-background="transparent"
					margin="0 8px"
					border-color="#ffffff"
				/>
				<Override slot="icon" border-color="#f1f5f9" />
			</SocialMedia>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"63a0914a4a50a9001ffae405"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});