import React from "react";
import theme from "theme";
import { Theme, Link, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia, Menu } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Özge Keskin
			</title>
			<meta name={"description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:description"} content={"Artist / Interior Architect & Designer"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/o%CC%88zge%20cocoon.jpeg?v=2022-12-19T17:07:45.972Z"} />
			<meta name={"msapplication-TileColor"} content={"#5d1111"} />
		</Helmet>
		<Section color="#000000" background="#000000">
			<Override slot="SectionContent" color="#4b44ff" border-color="#0276cd" font="14px --fontFamily-sansHelvetica" />
			<Text
				margin="0px 0 24px 0"
				text-align="right"
				font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				md-font="--headline3"
				border-color="#0276cd"
				color="#0276cd"
				align-self="flex-end"
			>
				<Link
					href="https://linktr.ee/ozgekeskin"
					border-color="#0276cd"
					color="#0276cd"
					text-decoration-line="initial"
					sm-font="500 19px/33.6px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
					md-font="500 19px/33.6px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
					font="500 19px/24px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
				>
					Linktree
				</Link>
			</Text>
		</Section>
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/dfghj.jpg?v=2022-12-19T20:25:02.142Z) 50% 15%/contain scroll"
			color="--light"
			font="--base"
			lg-background="--color-dark url(https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/dfghj.jpg?v=2022-12-19T20:25:02.142Z) 50% 15%/cover"
			position="static"
			md-background="--color-dark url(https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/dfghj.jpg?v=2022-12-19T20:25:02.142Z) 50% 15%/cover"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="50px"
					text-transform="uppercase"
					font="100 132px/1.2 --fontFamily-sans"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
					md-display="block"
				>
					ÖZGE
					<br />
					KESKİn
				</Text>
			</Box>
			<Text
				margin="0px 0px 0px 0px"
				text-align="center"
				font="300 14px/24px --fontFamily-sans"
				opacity="1"
				mix-blend-mode="normal"
				border-color="rgba(247, 251, 255, 0.49)"
			>
				I am a multidisciplinary artist. In addition to my training in fine arts, I am an interior architect and designer.
				<br />
				<br />
				I work on canvas and incorporate oil paint and mixed techniques into my physical works. My work have been featured in various international exhibitions.(Karl & Ein ,Bonart , and more)
				<br />
				<br />
				I create physical art on large canvases to elevate the spirit of the space in which it exists. As a multidisciplinary artist, I feel that creating physical art is like performing on stage. Performance is a collaborative effort of my hands, my mind, and my whole body. On stage, I meet and interact with the audience, have the opportunity to touch their senses and emotions, and build a bridge between myself and them.
			</Text>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
					flex-direction="column"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/image_6487327%20%283%29.JPG?v=2023-05-01T14:38:26.546Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							height="522px"
						/>
					</Box>
				</LinkBox>
			</Box>
		</Section>
		<Section
			text-align="center"
			padding="80px 0"
			sm-padding="40px 0"
			background="#ffffff"
			color="#ffffff"
		>
			<Override slot="SectionContent" border-color="rgba(0, 0, 0, 0.06)" />
			<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" lg-background="#ffffff" color="#000000">
				<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
				<Box
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					lg-padding="0px 30px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 30px 0px"
					sm-justify-content="space-between"
					sm-align-items="center"
					md-align-items="center"
				>
					<Image max-width="340px" src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/unnamed%20%283%29.jpg?v=2022-12-19T22:45:24.609Z" md-margin="0px 0px 20px 0px" />
				</Box>
				<Box
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					font="23px --fontFamily-sansHelvetica"
				>
					<Text
						margin="0px 0px 40px 0px"
						color="--dark"
						font="normal 500 35px/1.2 --fontFamily-sans"
						md-margin="0px 0px 30px 0px"
						lg-border-color="#ffffff"
						border-color="--color-lightD2"
						padding="10px 0px 0px 0px"
						lg-font="normal 500 32px/1.2 --fontFamily-sans"
						md-font="normal 500 32px/1.2 --fontFamily-sansHelvetica"
					>
						Reaching the limits of my hands, my mind, and my body and transforming into the work I produce reminds me that I am alive with the art that lives through me and comes to life.{"\n\n"}
					</Text>
				</Box>
			</Section>
		</Section>
		<Section
			padding="80px 0 80px 0"
			lg-padding="56px 0 56px 0"
			sm-padding="32px 0 32px 0"
			background="#000000"
			justify-content="center"
			color="#000000"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				align-self="center"
				sm-display="block"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				justify-items="center"
				align-items="center"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="center"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/63a0914a4a50a9001ffae407/images/aaaaaaaaay.jpg?v=2022-12-20T07:22:33.614Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							height="522px"
						/>
					</Box>
				</LinkBox>
			</Box>
		</Section>
		<Section
			background="--color-dark"
			padding="60px 0"
			sm-padding="40px 0"
			border-color="#000000"
			md-background="#000000"
		>
			<SocialMedia twitter="https://twitter.com/ozgekeskin_" instagram="https://www.instagram.com/ozgekeskin.ok/" linked-in="https://www.linkedin.com/in/%C3%B6zge-keskin-854676b5">
				<Override
					slot="link"
					border-radius="50%"
					color="--grey"
					hover-color="--light"
					background="transparent"
					hover-background="transparent"
					margin="0 8px"
					border-color="#ffffff"
				/>
				<Override slot="icon" border-color="#f1f5f9" />
			</SocialMedia>
		</Section>
		<Section background="#060606">
			<Menu
				display="flex"
				justify-content="center"
				font="--base"
				font-weight="700"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override
					slot="link"
					text-decoration="none"
					color="#8e8e8e"
					padding="6px 12px"
					lg-color="#8e8e8e"
				/>
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px" color="#673a3a" lg-color="#a8a5a5" />
			</Menu>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"63a0914a4a50a9001ffae405"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});